/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadUpIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M5.7 16.1L12 9.7l6.3 6.4.9-.9L12 7.9l-7.2 7.3z" />
	</svg>
))
ArrowheadUpIconSvg.displayName = 'ArrowheadUpIconSvg'

const ArrowheadUpIcon = forwardRef((props, ref) => <Icon component={ArrowheadUpIconSvg} ref={ref} {...props} />)
ArrowheadUpIcon.displayName = 'ArrowheadUpIcon'

ArrowheadUpIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadUpIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadUpIcon
export { ArrowheadUpIconSvg }
